import type { LoaderFunction, ActionFunction } from 'remix';
import { Form, redirect, useActionData, json } from 'remix';
import { z, ZodError } from 'zod';
import { createSession } from '~/utils/session.server';
import { supabase } from '~/utils/supabase';

export const loader: LoaderFunction = async ({ request }) => {
  const sesh = await supabase.auth.api.getUserByCookie(request);

  if (sesh.user) return redirect('/profile');

  return null;
};

type ActionData = {
  formError?: string;
  fieldErrors?: {
    name: 'email' | 'password' | undefined;
    content: ZodError | undefined;
  };
  fields?: {
    name: string;
    content: string;
  };
};

const badRequest = (data: ActionData) => json(data, { status: 400 });

export const action: ActionFunction = async ({ request }) => {
  const form = await request.formData();

  const email = form.get('email');
  const password = form.get('password');

  if (typeof email !== 'string' || typeof password !== 'string')
    return { formError: 'email and password should be a string' };

  const isEmail = z.string().email().nonempty().safeParse(email);
  const isPassword = z.string().min(6).max(66).safeParse(password);

  if (!isEmail.success)
    return badRequest({
      fieldErrors: {
        name: 'email',
        content: isEmail.error,
      },
    });

  if (!isPassword.success)
    return badRequest({
      fieldErrors: {
        name: 'password',
        content: isPassword.error,
      },
    });

  const { error, session } = await supabase.auth.signIn({
    email,
    password,
  });

  if (error) return badRequest({ formError: error?.message });

  if (typeof session?.refresh_token === 'undefined')
    return badRequest({ formError: 'no access_token returned' });

  const headers = await createSession(
    session.access_token,
    session.refresh_token,
  );

  return redirect('/profile', headers);
};

export default function Login() {
  const actionData = useActionData<ActionData>();
  return (
    <Form
      method="post"
      className="flex min-h-full flex-col justify-center items-center"
    >
      {actionData?.formError && (
        <div className="form-error">{actionData.formError}</div>
      )}
      <div className="mb-4 w-64">
        <label
          htmlFor="email"
          className="block uppercase tracking-wide text-xs font-bold mb-2"
        >
          email
        </label>
        <input
          id="email"
          type="email"
          name="email"
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          placeholder="your email"
        />
        {actionData?.fieldErrors?.name === 'email' && (
          <div className="form-error">
            {actionData?.fieldErrors?.content?.issues?.[0]?.message}
          </div>
        )}
      </div>
      <div className="mb-4 w-64">
        <label className="block uppercase tracking-wide text-xs font-bold mb-2">
          password
        </label>
        <input
          type="password"
          name="password"
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          placeholder="your password"
        />
        {actionData?.fieldErrors?.name === 'password' && (
          <div className="form-error">
            {actionData?.fieldErrors?.content?.issues?.[0]?.message}
          </div>
        )}
      </div>
      <button
        className="px-4 py-2 rounded-md bg-primary text-light outline-none"
        type="submit"
      >
        submit
      </button>
    </Form>
  );
}
